import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
    fetchSearchResults,
    SuccessfulSearchResponse
} from './utils/fetch-search-results';
import { Deployment } from '../../types';

export interface SearchState {
    isLoading: boolean;
    searchTerm: string;
    searchResponse: SuccessfulSearchResponse;
}

const initialState: SearchState = {
    isLoading: false,
    searchTerm: '',
    searchResponse: {
        queryId: '',
        results: [],
        facets: {
            contentType: {},
            platform: {
                [Deployment.CLOUD]: { count: 0, name: '', products: {} },
                [Deployment.DATA_CENTER]: { count: 0, name: '', products: {} }
            }
        }
    }
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        updateSearchTerm: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearchResults.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchSearchResults.fulfilled, (state, action) => {
                state.isLoading = false;
                state.searchResponse = action.payload || {
                    queryId: '',
                    results: [],
                    facets: []
                };
            })
            .addCase(fetchSearchResults.rejected, (state) => {
                state.isLoading = false;
            });
    }
});

export const { updateSearchTerm } = searchSlice.actions;

export default searchSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
    Content,
    Deployment,
    ProductFilters,
    SearchOptionsGenericValue,
    type FilterValue,
    SortBy
} from '../types';
import {
    getProductFiltersByDeployment,
    getVersionFilters,
    getVersionFiltersV2
} from '../../../utils/get-filters';
import { SearchResultsPageEntry } from '../search-results';
import { ListFilterOption } from '../../../components/list-filter';

export interface FilterState {
    entry: SearchResultsPageEntry;
    contentTypeQueryValue: Content[];
    deploymentQueryValue: Deployment; // Remove when isSacSearch2Enabled is fully implemented
    deploymentQueryValueV2?: Deployment;
    productsQueryValue: string[];
    productsFilterValues: FilterValue[]; // Remove when isSacSearch2Enabled is fully implemented
    productsFilterValuesV2: ProductFilters;
    productsTitleReset: boolean; // Remove when isSacSearch2Enabled is fully implemented
    versionsQueryValue: string[];
    versionsFilterValues: FilterValue[]; // Remove when isSacSearch2Enabled is fully implemented
    versionsFilterValuesV2: ListFilterOption[];
    sortByQueryValue: SortBy;
    sortByFilterValues: SearchOptionsGenericValue[];
    showVersionsFilter: boolean; // Remove when isSacSearch2Enabled is fully implemented
    versionsTitleReset: boolean; // Remove when isSacSearch2Enabled is fully implemented
    shouldPulseProductFilter: boolean; // Remove when isSacSearch2Enabled is fully implemented
    shouldPulseVersionFilter: boolean; // Remove when isSacSearch2Enabled is fully implemented
    resetFilters: boolean; // Remove when isSacSearch2Enabled is fully implemented
}

const initialState: FilterState = {
    entry: {
        id: '',
        type: '',
        url: '',
        resources: {
            id: '',
            cards: [],
            type: ''
        },
        header: {
            resources: undefined,
            additionalLinks: [],
            logo: '',
            id: '',
            showPreviewBanner: false,
            pageId: '',
            atlassianLogo: {
                url: ''
            },
            atlassianSupportLogo: {
                url: ''
            },
            serverProducts: [],
            cloudProducts: []
        },
        preview: false,
        filters: {
            products: [],
            documentTypes: []
        },
        searchOptions: {
            filters: {
                products: {
                    [Deployment.CLOUD]: [],
                    [Deployment.DATA_CENTER]: []
                },
                documentTypes: []
            },
            sortOptions: []
        }
    },
    contentTypeQueryValue: Object.values(Content),
    deploymentQueryValue: Deployment.CLOUD, // Remove when isSacSearch2Enabled is fully implemented
    deploymentQueryValueV2: undefined,
    productsQueryValue: [],
    productsFilterValues: [], // Remove when isSacSearch2Enabled is fully implemented
    productsFilterValuesV2: {
        [Deployment.CLOUD]: [],
        [Deployment.DATA_CENTER]: []
    },
    productsTitleReset: false, // Remove when isSacSearch2Enabled is fully implemented
    versionsQueryValue: [],
    versionsFilterValues: [], // Remove when isSacSearch2Enabled is fully implemented
    versionsFilterValuesV2: [],
    showVersionsFilter: false, // Remove when isSacSearch2Enabled is fully implemented
    versionsTitleReset: false, // Remove when isSacSearch2Enabled is fully implemented
    shouldPulseProductFilter: false, // Remove when isSacSearch2Enabled is fully implemented
    shouldPulseVersionFilter: false, // Remove when isSacSearch2Enabled is fully implemented
    sortByQueryValue: SortBy.RELEVANCE,
    sortByFilterValues: [{ key: SortBy.RELEVANCE, name: 'Relevance' }],
    resetFilters: false // Remove when isSacSearch2Enabled is fully implemented
};

// Remove when isSacSearch2Enabled is fully implemented
const shouldShowVersionsFilter = (state: FilterState) => {
    return !(
        state.deploymentQueryValue === Deployment.CLOUD ||
        state.resetFilters ||
        (state.productsQueryValue.length === 0 &&
            state.deploymentQueryValue === Deployment.DATA_CENTER) ||
        !state.productsQueryValue
    );
};

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        // Remove when isSacSearch2Enabled is fully implemented
        resetAllValues: (state) => {
            state.contentTypeQueryValue = Object.values(Content);
            state.resetFilters = true;
            state.deploymentQueryValue = Deployment.CLOUD;
            state.productsQueryValue = [];
            state.productsFilterValues = getProductFiltersByDeployment(
                state.entry?.filters?.products,
                Deployment.CLOUD
            );
            state.versionsQueryValue = [];
            state.versionsFilterValues = [];
            state.showVersionsFilter = shouldShowVersionsFilter(state);
        },
        // Search v2
        resetAllValuesV2: (state) => {
            state.deploymentQueryValueV2 = undefined;
            state.productsQueryValue = [];
            state.versionsQueryValue = [];
        },
        setContentFilterQueryValue: (
            state,
            action: PayloadAction<Content[]>
        ) => {
            state.contentTypeQueryValue = action.payload;
        },
        // Remove when isSacSearch2Enabled is fully implemented
        setDeploymentFilterQueryValue: (
            state,
            action: PayloadAction<Deployment>
        ) => {
            state.deploymentQueryValue = action.payload;
            const getProductFilters = getProductFiltersByDeployment(
                state.entry?.filters?.products,
                state.deploymentQueryValue
            );
            state.productsFilterValues = getProductFilters;
            state.productsTitleReset = true;
            state.shouldPulseProductFilter =
                state.productsQueryValue.length > 0;
            state.productsQueryValue = [];
            state.showVersionsFilter = shouldShowVersionsFilter(state);
        },
        // Remove when isSacSearch2Enabled is fully implemented
        setProductsFilterQueryValue: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.productsQueryValue = action.payload;
            state.showVersionsFilter = shouldShowVersionsFilter(state);
            if (state.showVersionsFilter) {
                state.versionsFilterValues = getVersionFilters(
                    state.productsQueryValue,
                    state.entry?.filters?.products
                );
                state.versionsTitleReset = true;
                state.shouldPulseVersionFilter =
                    state.versionsQueryValue.length > 0;
            }
            state.versionsQueryValue = [];
        },
        // Search v2
        setProductsFilterQueryValueV2: (
            state,
            action: PayloadAction<{ deployment: Deployment; product: string[] }>
        ) => {
            state.deploymentQueryValueV2 = action.payload.deployment;
            state.productsQueryValue = action.payload.product;
            if (state.deploymentQueryValueV2 === Deployment.DATA_CENTER) {
                state.versionsFilterValuesV2 = getVersionFiltersV2(
                    state.productsQueryValue?.[0],
                    state.entry?.searchOptions?.filters?.products
                );
            }
            state.versionsQueryValue = [];
        },
        setVersionsFilterQueryValue: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.versionsQueryValue = action.payload;
        },
        // Remove when isSacSearch2Enabled is fully implemented
        setResetFilters: (state, action: PayloadAction<boolean>) => {
            state.resetFilters = action.payload;
            state.showVersionsFilter = shouldShowVersionsFilter(state);
        },
        // Remove when isSacSearch2Enabled is fully implemented
        setProductTitleReset: (state) => {
            state.productsTitleReset = false;
        },
        // Remove when isSacSearch2Enabled is fully implemented
        setVersionsTitleReset: (state) => {
            state.versionsTitleReset = false;
        },
        // Remove when isSacSearch2Enabled is fully implemented
        setShouldPulseProductFilter: (state) => {
            state.shouldPulseProductFilter = false;
        },
        // Remove when isSacSearch2Enabled is fully implemented
        setShouldPulseVersionFilter: (state) => {
            state.shouldPulseVersionFilter = false;
        },
        setSortByQueryValue: (state, action: PayloadAction<SortBy>) => {
            state.sortByQueryValue = action.payload;
        },
        saveEntry: (state, action: PayloadAction<any>) => {
            state.entry = action.payload;
            // Remove when isSacSearch2Enabled is fully implemented
            state.productsFilterValues = getProductFiltersByDeployment(
                state.entry?.filters?.products,
                Deployment.CLOUD
            );
            // Search v2
            if (state.entry?.searchOptions?.filters?.products) {
                state.productsFilterValuesV2 =
                    state.entry?.searchOptions?.filters?.products;
            }
            if (state.entry?.searchOptions?.sortOptions) {
                state.sortByFilterValues =
                    state.entry?.searchOptions?.sortOptions;
            }
            // Remove when isSacSearch2Enabled is fully implemented
            state.showVersionsFilter = shouldShowVersionsFilter(state);
        }
    }
});

export const {
    setContentFilterQueryValue,
    setDeploymentFilterQueryValue,
    setProductsFilterQueryValue,
    setProductsFilterQueryValueV2,
    setVersionsFilterQueryValue,
    resetAllValues,
    resetAllValuesV2,
    setResetFilters,
    saveEntry,
    setProductTitleReset,
    setVersionsTitleReset,
    setShouldPulseProductFilter,
    setShouldPulseVersionFilter,
    setSortByQueryValue
} = filterSlice.actions;

export default filterSlice.reducer;

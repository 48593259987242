export const CAC_SEARCH_API = 'https://confluence.atlassian.com/search/';
export const DOCUMENTATION_CARD_CONTENT_ID = 'b3fTs5PrPymsKyewq2uee';
export const COMMUNITY_CARD_CONTENT_ID = '1jekufjl8YMw4eUKoI6kg6';
export const STATUS_CARD_CONTENT_ID = '5buCUK1TCgS2q6KMGkIkSy';
export const FEEDBACK_CARD_CONTENT_ID = '2nRNjDkbCcUwSOq422y66';
export const MARKETPLACE_CARD_CONTENT_ID = '77KyWObmQowc8oycAK6mo2';
export const BILLING_CARD_CONTENT_ID = 't7tq3EzI9qwcKMaES0i0y';
export const KNOWLEDGEBASE_CARD_CONTENT_ID = '1eb4CYi6GQecc4S0amoCGm';
export const MANAGE_ACCOUNT_LINK = 'https://id.atlassian.com/manage-profile';
export const SWITCH_ACCOUNT_LINK =
    'https://id.atlassian.com/login?prompt=select_account';
export const CONTENTFUL_APP_URL = 'https://app.contentful.com/spaces';
export const CONTENTFUL_LOGO =
    '//images.ctfassets.net/zsv3d0ugroxu/7egwUTJLYQ8Qsuke6Mgis2/d6e0ccc31ce1f059c67d3ddf40ddf91b/contentful.png';
export const CONFLUENCE_LOGO =
    'https://confluence.atlassian.com/staticassets/4.0.13/dist/common/images/product-icons/confluence.svg';

export const LOGIN_ATLASSIAN = 'https://id.atlassian.com/login';

export const APPLICATION_NAME = 'sac';

export const LOGIN_PROMPT_TYPES = {
    NONE: 'none',
    SELECT_ACCOUNT: 'select_account'
};

/**
 * Enumerates the values that the  {@link MouseEvent}
 */
export enum MouseEventButton {
    /** Represents a left (main) button click. */
    LEFT = 0,
    /** Represents a middle (scroll wheel) button click. */
    MIDDLE = 1,
    /** Represents a right button click. */
    RIGHT = 2,
    /** Represents a browser back button click. */
    BACK = 3,
    /** Represents a browser forward button click. */
    FORWARD = 4
}

declare global {
    interface Window {
        contentfulSpaceId: string;
        CCG_DETAILS_URL: string;
        CONTENTFUL_ENV: string;
        LOGOUT_URL: string;
        CONTACT_FORMS_MANIFEST: string;
        SEGMENT_K: string;
        LD_CLIENT_SDK_KEY: string;
        BANNERMAN_MANIFEST: string;
        SAC_CST_TOKEN_COOKIE_NAME: string;
        SAC_BASE_URL: string;
        ID_AUTH_URL: string;
        CONFLUENCE_URL: string;
    }
}

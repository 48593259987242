import React, { useEffect } from 'react';
import debounce from 'lodash/debounce';
import Spinner from '@atlaskit/spinner';
import { HeroBanner } from '../../components/hero-banner';
import { Search } from '../../components/search';
import Footer from '../../components/footer';
import { Header, HeaderProps, Resources } from '../../components/header';
import './search-results.less';
import { translatableText } from '../../utils/translatable-text';
import {
    SearchResultsDisplay,
    SearchResultCard,
    NoSearchResults
} from '../../components/search-results';
import FilterSelector from './components/filter-selector';
import ResetButton from './components/reset-button';
import {
    Deployment,
    deploymentsArray as deployments,
    contentArray,
    Content,
    FiltersApiResponse,
    SearchOptionsApiResponse,
    SortBy
} from './types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from './state/store';
import { updateSearchTerm } from './state/search/search-reducer';
import {
    SearchQuery,
    fetchSearchResults
} from './state/search/utils/fetch-search-results';
import Logger from '../../../logger';
import {
    resetAllValues,
    setContentFilterQueryValue,
    setDeploymentFilterQueryValue,
    setProductsFilterQueryValue,
    setVersionsFilterQueryValue,
    setProductTitleReset,
    setVersionsTitleReset,
    setShouldPulseProductFilter,
    setShouldPulseVersionFilter,
    saveEntry,
    setProductsFilterQueryValueV2,
    setSortByQueryValue,
    resetAllValuesV2
} from './state/filter-reducer';
import { ListFilter } from '../../components/list-filter';
import { Divider } from '../../components/divider';
import analytics from '../../utils/analytics';

const searchHandler = async (
    dispatch: AppDispatch,
    searchTerm: string,
    query: SearchQuery,
    featureFlags: {
        isSearchTermHighlightingEnabled?: boolean;
        isSacSearch2Enabled?: boolean;
    }
    // eslint-disable-next-line max-params
) => {
    try {
        dispatch(
            fetchSearchResults({
                searchTerm,
                query,
                featureFlags: {
                    isSearchTermHighlightingEnabled:
                        featureFlags.isSearchTermHighlightingEnabled,
                    isSacSearch2Enabled: featureFlags.isSacSearch2Enabled
                }
            })
        );
    } catch (error) {
        Logger.error({ error }, 'Error dispatching the search handler');
    }
};

const debouncedQueryHelpAggregator = debounce(searchHandler, 500);

export interface SearchResultsPageEntry {
    id: string;
    type: string;
    url: string;
    resources: Resources;
    header: HeaderProps;
    preview: boolean;
    filters: FiltersApiResponse;
    searchOptions: SearchOptionsApiResponse;
}

interface SearchResultsPageProps {
    entry: SearchResultsPageEntry;
    featureFlags: {
        isSearchTermHighlightingEnabled?: boolean;
        isSacSearch2Enabled?: boolean;
    };
}

// eslint-disable-next-line complexity
export const SearchResultsPage = ({
    entry,
    featureFlags
}: SearchResultsPageProps) => {
    const { isSearchTermHighlightingEnabled, isSacSearch2Enabled } =
        featureFlags;

    const { isLoading, searchTerm, searchResponse } = useSelector(
        (state: RootState) => state.search
    );

    const {
        contentTypeQueryValue,
        deploymentQueryValue,
        deploymentQueryValueV2,
        productsQueryValue,
        productsFilterValues,
        productsFilterValuesV2,
        productsTitleReset,
        versionsQueryValue,
        versionsFilterValues,
        versionsFilterValuesV2,
        versionsTitleReset,
        shouldPulseProductFilter,
        shouldPulseVersionFilter,
        showVersionsFilter,
        sortByQueryValue,
        sortByFilterValues
    } = useSelector((state: RootState) => state.filter);

    const dispatch: AppDispatch = useDispatch();
    const handleSearchChange = (searchValue: string) => {
        const action = updateSearchTerm(searchValue);
        dispatch(action);
    };

    const handleSubmit = (searchValue: string) => {
        if (!searchValue) {
            return;
        }

        // keep the search value and url in sync
        const url = new URL(window.location.href);
        url.searchParams.set('searchTerm', searchValue);
        window.history.replaceState({}, '', url);

        debouncedQueryHelpAggregator(
            dispatch,
            searchTerm,
            {
                deployment: isSacSearch2Enabled
                    ? deploymentQueryValueV2
                    : deploymentQueryValue,
                productsQueryValue,
                contentTypes: contentTypeQueryValue,
                version: versionsQueryValue,
                sortByQueryValue
            },
            {
                isSearchTermHighlightingEnabled:
                    isSearchTermHighlightingEnabled,
                isSacSearch2Enabled: isSacSearch2Enabled
            }
        );
    };

    const handleSortByFilterChange = (value: SortBy) => {
        const oldSortByQueryValue = sortByQueryValue;
        dispatch(setSortByQueryValue(value));
        analytics.trackEvent('Sort by filter', {
            // @ts-ignore
            event: 'clicked',
            eventComponent: 'filter',
            actionSubjectId: 'sort-by-filter',
            category: 'search results filter',
            action: 'clicked',
            // label: 'featured article',
            currentSelection: oldSortByQueryValue,
            newSelection: value
        });
    };

    useEffect(() => {
        handleSubmit(searchTerm);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        deploymentQueryValue,
        deploymentQueryValueV2,
        productsQueryValue,
        contentTypeQueryValue,
        versionsQueryValue,
        sortByQueryValue
    ]);

    // initialize the query from the url for when users first visit
    useEffect(() => {
        const parsedUrl = new URL(window.location.href);
        const searchTermFromUrl = parsedUrl.searchParams.get('searchTerm');
        dispatch(updateSearchTerm(searchTermFromUrl || ''));
        dispatch(saveEntry(entry));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Remove when isSacSearch2Enabled is fully implemented
    const renderVersionFilter = () => {
        if (showVersionsFilter) {
            return (
                <>
                    <FilterSelector
                        filterTitle="Version"
                        filterValues={versionsFilterValues}
                        setQueryValues={(value: string[]) =>
                            dispatch(setVersionsFilterQueryValue(value))
                        }
                        allowFilterAll={false}
                        titleReset={versionsTitleReset}
                        setTitleReset={() => dispatch(setVersionsTitleReset())}
                        shouldPulse={shouldPulseVersionFilter}
                        setShouldPulse={() =>
                            dispatch(setShouldPulseVersionFilter())
                        }
                    />
                </>
            );
        }
    };

    const renderNewVersionFilter = () => {
        if (deploymentQueryValueV2 === Deployment.DATA_CENTER) {
            return (
                <>
                    <Divider />
                    <ListFilter
                        title={'Version'}
                        selected={{
                            key: versionsQueryValue[0] || ''
                        }}
                        optionsLists={[{ options: versionsFilterValuesV2 }]}
                        isScrollable={true}
                        onChange={(optionKey: string) => {
                            dispatch(setVersionsFilterQueryValue([optionKey]));
                        }}
                    />
                </>
            );
        }
    };

    const renderSearchResults = () => {
        if (isSacSearch2Enabled) {
            return searchResponse.results.map((result: any, index: number) => (
                <SearchResultCard
                    key={result.content.contentAri}
                    id={result.content.contentAri}
                    title={
                        isSearchTermHighlightingEnabled
                            ? result?.highlights?.title || result.content.title
                            : result.content.title
                    }
                    description={
                        isSearchTermHighlightingEnabled
                            ? result?.highlights?.description ||
                              result.content.description
                            : result.content.description
                    }
                    href={result.content.url}
                    dateModified={result.content.metadata.updatedAt}
                    markedUseful={
                        result.content.metadata.trustFactors.helpfulCount
                    }
                    views={result.content.metadata.trustFactors.numViews}
                    searchTerm={searchTerm}
                    position={index + 1}
                    queryId={searchResponse.queryId}
                    products={result.content.products}
                    isSearchTermHighlightingEnabled={
                        isSearchTermHighlightingEnabled
                    }
                />
            ));
        } else {
            return searchResponse.results.map((result: any, index: number) => (
                <SearchResultCard
                    key={result.id}
                    id={result.id}
                    title={
                        isSearchTermHighlightingEnabled
                            ? result?.highlights?.title || result.title
                            : result.title
                    }
                    description={
                        isSearchTermHighlightingEnabled
                            ? result?.highlights?.description ||
                              result.description
                            : result.description
                    }
                    href={result.url}
                    dateModified={result.updatedDate}
                    markedUseful={result.likes}
                    views={result.views}
                    searchTerm={searchTerm}
                    position={index + 1}
                    queryId={searchResponse.queryId}
                    products={result.products}
                    deployment={deploymentQueryValue}
                    isSearchTermHighlightingEnabled={
                        isSearchTermHighlightingEnabled
                    }
                />
            ));
        }
    };

    // Render product filter v2
    const renderProductFilter = () => {
        return (
            <ListFilter
                onChange={(value, header) => {
                    dispatch(
                        setProductsFilterQueryValueV2({
                            deployment: header as Deployment,
                            product: [value]
                        })
                    );
                }}
                title="Product"
                selected={{
                    key: productsQueryValue[0],
                    header: deploymentQueryValueV2
                }}
                optionsLists={Object.keys(productsFilterValuesV2).map(
                    (deployment) => ({
                        header: deployment,
                        options: productsFilterValuesV2[
                            deployment as Deployment
                        ].map((product) => ({
                            key: product.key,
                            label: `${product.name} (${
                                searchResponse?.facets?.platform?.[
                                    deployment as Deployment
                                ]?.products?.[product.key]?.count || 0
                            })`
                        }))
                    })
                )}
            />
        );
    };

    const subHeaderText = isSacSearch2Enabled
        ? translatableText.heroBannerSubheaderSacSearch2.text
        : translatableText.heroBannerSubheader.text;

    return (
        <div className="content-wrapper">
            <Header
                id={entry.header.id}
                pageId={entry.id}
                resources={entry.resources}
                additionalLinks={entry.header.additionalLinks}
                atlassianLogo={entry.header.atlassianLogo}
                logo={entry.header.atlassianSupportLogo.url}
                showPreviewBanner={entry.preview}
                atlassianSupportLogo={entry.header.atlassianSupportLogo}
                serverProducts={entry.header.serverProducts}
                cloudProducts={entry.header.cloudProducts}
            />
            <main>
                <div data-testid="search-results-page">
                    <HeroBanner
                        searchField={
                            <Search
                                onSearch={handleSubmit}
                                placeholder={
                                    translatableText.searchPlaceholder.text
                                }
                                initialValue={searchTerm}
                                analyticsMetadata={{
                                    parentComponent: 'search-results'
                                }}
                                onChange={handleSearchChange}
                            />
                        }
                        subHeader={subHeaderText}
                    />
                    {isSacSearch2Enabled ? (
                        <div className="above-search-cards-container">
                            <div className="resource-type-tabs">
                                Resource type tabs
                            </div>
                            <div className="search-results-summary">
                                5 results for lorem ipsum found.
                            </div>
                            <div className="sort-by-filter-section">
                                <div className="sort-by-filter-text">
                                    {translatableText.sortBy.text}
                                </div>
                                <FilterSelector
                                    filterTitle={translatableText.sortBy.text}
                                    filterValues={sortByFilterValues}
                                    allowFilterAll={false}
                                    setQueryValues={handleSortByFilterChange}
                                    queryByString
                                    defaultSelected={sortByFilterValues[0].name}
                                    showBorder
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="filters-container">
                            <FilterSelector
                                filterTitle="Deployment"
                                filterValues={deployments}
                                setQueryValues={(value: Deployment) =>
                                    dispatch(
                                        setDeploymentFilterQueryValue(value)
                                    )
                                }
                                allowFilterAll={false}
                                queryByString={true}
                                defaultSelected={deployments[0].name}
                            />
                            <FilterSelector
                                filterTitle="Product"
                                filterValues={productsFilterValues}
                                setQueryValues={(value: string[]) =>
                                    dispatch(setProductsFilterQueryValue(value))
                                }
                                titleReset={productsTitleReset}
                                setTitleReset={() =>
                                    dispatch(setProductTitleReset())
                                }
                                shouldPulse={shouldPulseProductFilter}
                                setShouldPulse={() =>
                                    dispatch(setShouldPulseProductFilter())
                                }
                            />
                            {renderVersionFilter()}
                            <FilterSelector
                                filterTitle="Resource Type"
                                filterValues={contentArray}
                                setQueryValues={(value: Content[]) =>
                                    dispatch(setContentFilterQueryValue(value))
                                }
                                allowFilterAll={true}
                            />
                            <ResetButton
                                appearance="subtle"
                                onReset={() => {
                                    dispatch(resetAllValues());
                                    return false; // Don't send analytics event for old "Clear filters" button
                                }}
                            />
                        </div>
                    )}

                    {isSacSearch2Enabled ? (
                        <div className="search-results-grid">
                            <div className="search-filters">
                                <ResetButton
                                    appearance="default"
                                    analyticsMetadata={{
                                        searchTerm,
                                        clearedDeployment:
                                            deploymentQueryValueV2,
                                        clearedProducts: productsQueryValue,
                                        clearedVersions: versionsQueryValue
                                    }}
                                    onReset={() => {
                                        const shouldReset =
                                            !!productsQueryValue?.length;
                                        if (shouldReset) {
                                            dispatch(resetAllValuesV2());
                                        }
                                        return shouldReset;
                                    }}
                                />
                                <Divider />
                                <div>filter tags</div>
                                <Divider />
                                {renderProductFilter()}
                                {renderNewVersionFilter()}
                            </div>
                            <SearchResultsDisplay
                                isSacSearch2Enabled={isSacSearch2Enabled}
                            >
                                {isLoading ? (
                                    <div className="search-results-spinner-container">
                                        <Spinner size={'xlarge'} />
                                    </div>
                                ) : searchResponse?.queryId &&
                                  searchResponse.results?.length ? (
                                    renderSearchResults()
                                ) : (
                                    <NoSearchResults searchTerm={searchTerm} />
                                )}
                            </SearchResultsDisplay>
                        </div>
                    ) : (
                        <SearchResultsDisplay
                            isSacSearch2Enabled={isSacSearch2Enabled}
                        >
                            {isLoading ? (
                                <div className="search-results-spinner-container">
                                    <Spinner size={'xlarge'} />
                                </div>
                            ) : searchResponse?.queryId &&
                              searchResponse.results?.length ? (
                                renderSearchResults()
                            ) : (
                                <NoSearchResults searchTerm={searchTerm} />
                            )}
                        </SearchResultsDisplay>
                    )}
                </div>

                {isSacSearch2Enabled && (
                    <div className="pagination">pagination</div>
                )}
            </main>
            <Footer
                logo={entry.header.atlassianLogo.url}
                className={'margin-top-large'}
            />
        </div>
    );
};
